import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--request-form--supplier-search"
export default class extends Controller {
  connect() {
    this.element.addEventListener("select", this.submitSupplier.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("select", this.submitSupplier.bind(this))
  }

  submitSupplier(e) {
    const supplierId = e.detail.value
    const supplierName = this.element.querySelector('[data-test="selected-supplier-display-name"]').textContent.trim()
    const value = `<span data-supplier-id="${supplierId}">${supplierName}</span>`

    const formElement = this.element.closest("form")
    const fieldController = this.application.getControllerForElementAndIdentifier(
      formElement,
      "ai--request-form--field",
    )
    fieldController.submitValue(
      new CustomEvent("supplierSearch", {
        detail: { value: value },
      }),
    )
  }
}
