import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manual-price-check-form"
export default class extends Controller {
  static targets = ["removeLineItemButton", "fullForm", "briefForm", "notAvailableForm"]
  removeLineItemButtonTargets: HTMLLinkElement[]
  fullFormTarget: HTMLFormElement
  briefFormTarget: HTMLFormElement
  notAvailableFormTarget: HTMLFormElement

  connect() {
    this.refreshState()
    addEventListener("nested-form:change", this.refreshState.bind(this))
  }

  disconnect() {
    removeEventListener("nested-form:change", this.refreshState.bind(this))
  }

  refreshState() {
    this.updateSKULabels()
    this.updateDeleteDisabledState()
  }

  updateSKULabels() {
    this.visibleSKUTargets().forEach((skuElement, index) => {
      skuElement.querySelector("h2").textContent = `SKU ${index + 1}`
    })
  }

  visibleSKUTargets() {
    return this.element.querySelectorAll(
      'section.mpc_line_item:not([style*="display:none"]):not([style*="display: none"])',
    )
  }

  // We don't allow removing the last line item on a form
  updateDeleteDisabledState() {
    let skuCount = this.visibleSKUTargets().length
    if (skuCount === 1) {
      this.removeLineItemButtonTargets.forEach((button) => {
        button.disabled = true
      })
    } else {
      this.removeLineItemButtonTargets.forEach((button) => {
        button.disabled = false
      })
    }
  }

  updateFormType(e) {
    this.mirrorFormTypeSelection(e.currentTarget.value)

    if (e.currentTarget.value === "full") {
      this.switchFormToFull()
    } else if (e.currentTarget.value === "brief") {
      this.switchFormToBrief()
    } else {
      this.switchFormToNotAvailable()
    }
  }

  mirrorFormTypeSelection(selection: String) {
    this.element
      .querySelectorAll(`input[name="manual_price_check[price_check_type]"][value="${selection}"]`)
      .forEach((input: HTMLInputElement) => {
        input.checked = true
      })
  }

  switchFormToFull() {
    this.briefFormTarget.classList.add("hidden")
    this.notAvailableFormTarget.classList.add("hidden")
    this.fullFormTarget.classList.remove("hidden")

    this.fullFormTarget.id = "manual_price_check_form"
    this.briefFormTarget.id = "brief_manual_price_check_form"
    this.notAvailableFormTarget.id = "not_available_manual_price_check_form"
  }

  switchFormToBrief() {
    this.fullFormTarget.classList.add("hidden")
    this.notAvailableFormTarget.classList.add("hidden")
    this.briefFormTarget.classList.remove("hidden")

    this.fullFormTarget.id = "full_manual_price_check_form"
    this.briefFormTarget.id = "manual_price_check_form"
    this.notAvailableFormTarget.id = "not_available_manual_price_check_form"
  }

  switchFormToNotAvailable() {
    this.fullFormTarget.classList.add("hidden")
    this.briefFormTarget.classList.add("hidden")
    this.notAvailableFormTarget.classList.remove("hidden")

    this.fullFormTarget.id = "full_manual_price_check_form"
    this.briefFormTarget.id = "brief_manual_price_check_form"
    this.notAvailableFormTarget.id = "manual_price_check_form"
  }
}
