import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ai--request-form--field"
export default class extends Controller {
  static targets = ["form", "messageTextarea"]

  formTarget: HTMLFormElement
  messageTextareaTarget: HTMLTextAreaElement
  selectedOptions: string[]

  connect() {}

  submitButtonOption = (event: Event) => {
    event.preventDefault()
    const value = (event.currentTarget as HTMLButtonElement).value

    this.messageTextareaTarget.value = value
    this.formTarget.requestSubmit()
  }

  submitValue = (event: Event) => {
    event.preventDefault()
    const value = event instanceof CustomEvent ? event.detail.value : event.params.value

    this.messageTextareaTarget.value = value
    this.formTarget.requestSubmit()
  }

  toggleCheckbox(event) {
    event.preventDefault()
    const checkbox = event.currentTarget
    const value = checkbox.value

    if (!Array.isArray(this.selectedOptions)) this.selectedOptions = []

    if (checkbox.checked) this.selectedOptions.push(value)
    else this.selectedOptions = this.selectedOptions.filter((option) => option !== value)
  }

  submitCheckboxOptions = (event: Event) => {
    event.preventDefault()
    this.messageTextareaTarget.value = this.selectedOptions.join(", ")
    this.formTarget.requestSubmit()
  }

  // PB: Method used for contract selection in the AI Assistant
  submitContracts = (event: Event) => {
    event.preventDefault()
    if (!Array.isArray(this.selectedOptions)) this.selectedOptions = []
    if (this.selectedOptions.length === 0) {
      this.messageTextareaTarget.value = "No Contract"
    } else {
      this.selectedOptions = this.selectedOptions.map((option) => {
        const optionElement = document.getElementById(`chat_contract_option_${option}`) as HTMLElement
        if (optionElement) {
          return optionElement.outerHTML
        }
      })
      this.messageTextareaTarget.value = this.selectedOptions.join("</br>")
    }
    this.formTarget.requestSubmit()
  }

  selectSuggestion = (event: Event) => {
    event.preventDefault()

    const target = event.currentTarget as HTMLElement
    const suggestionValue = target.dataset.suggestionValue

    this.messageTextareaTarget.value = suggestionValue
    this.formTarget.requestSubmit()
  }

  submitSelectOption = (event: Event) => {
    const selectField = event.target.tomselect
    const value = selectField.getValue()
    const chosenOption = selectField.getItem(value)
    this.messageTextareaTarget.value = chosenOption.outerHTML
    this.formTarget.requestSubmit()
  }
}
